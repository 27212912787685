import React from 'react'
import { Link, useNavigate, Outlet } from 'react-router-dom'
import { Button, Header, Segment, Icon, Label, Menu, Sidebar, Message, List } from 'semantic-ui-react'
import { authenticator } from '../../authenticator'
import { getEnglishText } from '../../dictionary'

import { Subscriptions } from './Subscriptions'
import { InstallDetails } from '../InstallDetails/InstallDetails'

import pckg from '../../../package.json'

import './MainMenu.css'

function MainMenu({ user, units, visible }) {
  const navigate = useNavigate()
  const { version } = pckg || {}

  // const [triggerLoad, setTriggerLoad] = useState()

  const userEmail = authenticator.getUserDetails()['email']

  // useEffect(() => {
  //   setTriggerLoad(visible)
  // }, [visible])

  function isOwner() {
    return user && user.user && user.user.isStaff === false && user.user.isInstaller === false
  }

  if (!user || !user.user) return null

  return (
    <Sidebar
      id="MainMenu"
      as={Menu}
      animation="overlay"
      icon="labeled"
      direction="right"
      vertical
      width="very wide"
      onHide={() => {
        navigate('/home')
      }}
      visible={visible}
    >
      <div className="top-controls">
        <Button floated="left" circular className="close-button" icon as={Link} to="/home">
          <Icon name="close" />
        </Button>
      </div>

      <Header className="hero" as="h2" icon textAlign="center">
        <Icon name="user" circular />
        <span className="user">{userEmail}</span>
        {user.user && user.user.isStaff === true && (
          <Label size="mini" color="teal">
            staff
          </Label>
        )}
        {user.user && user.user.isInstaller === true && (
          <Label size="mini" color="teal">
            installer
          </Label>
        )}
        <br />
        <Button as={Link} to={'/logout'} secondary size="tiny" className="sign-out-button">
          {getEnglishText('common : sign-out')}
        </Button>
      </Header>

      <div className="middle-controls">
        {/* Account */}

        {/* <Header as="h3">Account</Header>

        <Segment basic>
          <List relaxed="very">
            <List.Item>
              <Link to="">Change Password</Link>
            </List.Item>
            <List.Item>
              <Link to="">Additional Users</Link>
            </List.Item>
          </List>
        </Segment> */}

        {/* Install Details */}

        <Header as="h3">{getEnglishText('main-menu : install-details')}</Header>

        {/* Install Details */}

        <InstallDetails user={user} units={units} visible={visible} />

        {/* Subscriptions */}

        {/* <Header as="h3">{getEnglishText('main-menu : subscriptions')}</Header>

        <Segment basic style={{ marginRight: '1em' }} clearing>
          <Subscriptions units={units} user={user} visible={visible} managementEmail={userEmail || ''} />
        </Segment> */}

        {/* About */}

        <Header as="h3">{getEnglishText('main-menu : more')}</Header>

        <Segment basic>
          <List relaxed>
            <List.Item>
              <Link to="/home/account/release-notes">{getEnglishText('main-menu : release-notes')}</Link>&nbsp;&nbsp;<small>v{version}</small>
            </List.Item>
          </List>
        </Segment>
      </div>

      <Outlet />
    </Sidebar>
  )
}

export { MainMenu }
