import React, { useState, useEffect } from 'react'
import moment, { localeData } from 'moment'
import { authenticator } from '../../authenticator'
import { config, debugMessage, friendlyFormat } from '../../common'
import { ajax } from '../../ajax'
import { Segment, Message, Statistic, Button, Dropdown, Loader, Header, Icon, Divider } from 'semantic-ui-react'
import { sumChartData } from '../../lib/groupUnitData'
import { getEnglishText } from '../../dictionary'

import { Savings } from './Savings'

import './Totals.css'

let refreshHandle = null
let lastRequestTime = 0

function Totals({ units, dateLocal }) {
  const chartsDate = dateLocal
  const [startDateLocal, setStartDateLocal] = useState(chartsDate)
  const [endDateLocal, setEndDateLocal] = useState(null) // only used by component sub title
  const chartsAreToday = chartsDate.isSame(moment(), 'day')
  const isToday = startDateLocal.isSame(moment(), 'day')
  const [rangeName, setRangeName] = useState(null)
  const [earliestDateFrom, setEarliestDateFrom] = useState(null)

  const [isLoaded, setIsLoaded] = useState(false)
  const [error, setError] = useState(null)
  const [warning, setWarning] = useState(null)
  const [data, setData] = useState([])
  const refreshInterval = 1000 * 60 * 5 // 5 minutes

  const [clusters, setClusters] = useState([])
  const [displayAsClusters, setDisplayAsClusters] = useState(false)

  useEffect(() => {
    debugMessage('Mounted', 'Totals')
  }, [])

  useEffect(() => {
    debugMessage('Updated', 'Totals')
    gotoChartDate()
    return () => {
      clearTimeout(refreshHandle)
    }
  }, [units, chartsDate])

  function fetchData({ start, end, noLoader }) {
    setWarning()
    if (!units || !units[0]) return setWarning('No unit ID(s)')

    if (!noLoader) setIsLoaded(false)

    const idToken = authenticator.getToken()

    lastRequestTime = new Date().getTime()
    let thisRequestTime = new Date().getTime()

    debugMessage(`Requesting range ${moment(start).format('YYYY-MM-DD HH:mm:ss')} (Local) - ${moment(end).format('YYYY-MM-DD HH:mm:ss')} (Local)`, 'Totals')

    ajax
      .fetchChartData(idToken, {
        units,
        startDateLocal: start,
        endDateLocal: end,
        totalsOnly: true
      })
      .then(({ clusters }) => {
        if (lastRequestTime !== thisRequestTime) {
          debugMessage('Data from old request, discarding', 'Totals')
          return
        }

        // console.log('clusters-daily', clusters)

        let chartData = null

        if (clusters[0].units[0].clusterHash) {
          setDisplayAsClusters(true)
        } else {
          setDisplayAsClusters(false)
        }

        // sum chart data if more than one unit
        if (clusters.length > 1) {
          chartData = sumChartData(clusters)
          setClusters(clusters)
        } else {
          chartData = clusters[0]
          setClusters([])
        }

        if (data) {
          let { requestedTimeFromMs, requestedTimeToMs, earliestDateFromMs } = chartData

          const errorDays = Math.abs(moment.utc(requestedTimeFromMs).diff(moment.utc(earliestDateFromMs), 'days'))

          if (errorDays > 0) {
            setEarliestDateFrom(moment.utc(earliestDateFromMs))
          } else {
            setEarliestDateFrom(null)
          }

          debugMessage(
            `Received range   ${moment.utc(earliestDateFromMs).format('YYYY-MM-DD HH:mm:ss')} (UTC)   - ${moment
              .utc(requestedTimeToMs)
              .format('YYYY-MM-DD HH:mm:ss')} (UTC)`,
            'Totals'
          )

          setData(chartData)
          setIsLoaded(true)
        } else {
          setIsLoaded(true)
          setWarning('No data')
        }
      })
      .catch((error) => {
        debugMessage(error)
        setError(error)
      })

    clearTimeout(refreshHandle)
    refreshHandle = setTimeout(() => {
      fetchData({ start, end, noLoader: true })
    }, refreshInterval)
  }

  function gotoChartDate() {
    const start = chartsDate.clone().startOf('day')
    const end = start.clone().add(24, 'hours').subtract(1, 'millisecond') // span 1 day

    setRangeName(null)
    setStartDateLocal(start)
    setEndDateLocal(end)
    fetchData({ start, end })
  }

  function gotoToday() {
    const start = moment().startOf('day')
    const end = start.clone().add(24, 'hours').subtract(1, 'millisecond') // span 1 day

    setRangeName(null)
    setStartDateLocal(start)
    setEndDateLocal(end)
    fetchData({ start, end })
  }

  function gotoThisWeek() {
    const start = moment().startOf('isoWeek')
    const end = moment().endOf('day') // span until end of today

    setRangeName('this week')
    setStartDateLocal(start)
    setEndDateLocal(end)
    fetchData({ start, end })
  }

  function gotoThisMonth() {
    const start = moment().startOf('month')
    const end = moment().endOf('day') // span until end of today

    setRangeName('this month')
    setStartDateLocal(start)
    setEndDateLocal(end)
    fetchData({ start, end })
  }

  function gotoThisYear() {
    const start = moment().startOf('year')
    const end = moment().endOf('day') // span until end of today

    setRangeName('this year')
    setStartDateLocal(start)
    setEndDateLocal(end)
    fetchData({ start, end })
  }

  function gotoYesterday() {
    const start = moment().subtract(1, 'day').startOf('day')
    const end = start.clone().add(24, 'hours').subtract(1, 'millisecond') // span 1 day

    setRangeName('yesterday')
    setStartDateLocal(start)
    setEndDateLocal(end)
    fetchData({ start, end })
  }

  function gotoLastWeek() {
    const start = moment().startOf('isoWeek').subtract(1, 'week')
    const end = start.clone().endOf('isoWeek') // span until end of same week

    setRangeName('last week')
    setStartDateLocal(start)
    setEndDateLocal(end)
    fetchData({ start, end })
  }

  function gotoLastMonth() {
    const start = moment().subtract(1, 'month').startOf('month')
    const end = start.clone().endOf('month') // span until end of same month

    setRangeName('last month')
    setStartDateLocal(start)
    setEndDateLocal(end)
    fetchData({ start, end })
  }

  function gotoLastYear() {
    const start = moment().subtract(1, 'year').startOf('year')
    const end = start.clone().endOf('year') // span until end of same year

    setRangeName('last year')
    setStartDateLocal(start)
    setEndDateLocal(end)
    fetchData({ start, end })
  }

  function getFriendlyTitle() {
    switch (rangeName) {
      case null:
        return moment(startDateLocal).format('Do MMMM YYYY')
      case 'this week':
      case 'this month':
      case 'this year':
        return `${startDateLocal.format('Do MMMM YYYY')} to now`
      case 'yesterday':
        return `${startDateLocal.format('Do MMMM YYYY')}`
      default:
        return (
          <>
            {startDateLocal.format('Do MMMM YYYY')} to <br />
            {endDateLocal.format('Do MMMM YYYY')}
          </>
        )
    }
  }

  let batteryCharge = 0
  let batteryDischarge = 0
  let gridExports = 0
  let gridImports = 0
  let homeConsumed = 0
  let solarConsumed = 0
  let solarGenerated = 0

  if (data && data.totals) {
    batteryCharge += data.totals.batteryCharge
    batteryDischarge += data.totals.batteryDischarge
    gridExports += data.totals.gridExports
    gridImports += data.totals.gridImports
    homeConsumed += data.totals.homeConsumed
    solarConsumed += data.totals.solarConsumed
    solarGenerated += data.totals.solarGenerated
  }

  ///////////////////////////////////////////////////////////////////////////////////
  // Hacky, hard-coded method to display the Savings component to only a few units //
  ///////////////////////////////////////////////////////////////////////////////////

  let displaySavings = false

  try {
    for (const u of units) {
      if (config.showSavingsOnlyForHardwareIds.includes(u.hardwareId)) {
        displaySavings = true
        break
      }
    }
  } catch (error) {}

  ///////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////

  return (
    <Segment id="daily">
      <Header as="h2">
        <Icon name="history" color="teal" />
        <Header.Content>
          {getEnglishText('totals : title')}&nbsp;&nbsp;
          {isLoaded && units.length > 1 && (
            <span className="clusters-desc" style={{ fontSize: clusters.length > 1 ? '75%' : '100%' }}>
              <>
                ({units.length} units
                {clusters.length > 1 && <>{`, in ${clusters.length} clusters`}</>})&nbsp;&nbsp;
              </>
            </span>
          )}
          <Header.Subheader>{getFriendlyTitle()}</Header.Subheader>
        </Header.Content>
      </Header>

      <Divider hidden />

      {error && (
        <Message negative>
          <p>Error: {error.message}</p>
        </Message>
      )}

      {warning && (
        <Message warning>
          <p>{warning}</p>
        </Message>
      )}

      <div className="date-range-buttons">
        <Button.Group basic size="small">
          <Button active={!rangeName && isToday} content={getEnglishText('totals : today')} onClick={gotoToday}></Button>
          <Button active={rangeName === 'this week'} content={getEnglishText('totals : this-week')} onClick={gotoThisWeek} />
          <Button active={rangeName === 'this month'} content={getEnglishText('totals : this-month')} onClick={gotoThisMonth} />
          <Button active={rangeName === 'this year'} content={getEnglishText('totals : this-year')} onClick={gotoThisYear} />
          <Dropdown
            className="button icon"
            icon="ellipsis horizontal"
            options={[
              { selected: rangeName === 'yesterday', key: 'yesterday', text: getEnglishText('totals : yesterday'), onClick: gotoYesterday },
              { selected: rangeName === 'last week', key: 'last week', text: getEnglishText('totals : last-week'), onClick: gotoLastWeek },
              { selected: rangeName === 'last month', key: 'last month', text: getEnglishText('totals : last-month'), onClick: gotoLastMonth },
              { selected: rangeName === 'last year', key: 'last year', text: getEnglishText('totals : last-year'), onClick: gotoLastYear }
            ]}
            trigger={<></>}
          />
        </Button.Group>

        {!chartsAreToday && (
          <>
            <br />
            <Button primary={!rangeName && !isToday} basic size="small" content={`${chartsDate.format('D MMMM, YYYY')}`} onClick={gotoChartDate} />
          </>
        )}
      </div>

      {!isLoaded ? (
        <>
          <Loader active inline="centered" />
        </>
      ) : (
        <div className="container">
          {earliestDateFrom !== null && (
            <>
              <Message warning>
                {getEnglishText('totals : earliest-data-is-from')}
                <br />
                <strong>{earliestDateFrom.format('Do MMMM YYYY')}</strong>
              </Message>
              <Divider hidden />
            </>
          )}

          <Divider horizontal>
            <Header as="h4">
              {getEnglishText('totals : solar')}&nbsp;&nbsp;
              <Icon name="sun" className="pv-yellow-color" />
            </Header>
          </Divider>
          <Statistic size="tiny">
            <Statistic.Value>
              <span>{getEnglishText('totals : solar-generated')}</span>
              {friendlyFormat('daily_total_solar_generated', solarGenerated)[0]}
            </Statistic.Value>
            <Statistic.Label>{friendlyFormat('daily_total_solar_generated', solarGenerated)[1]}</Statistic.Label>
          </Statistic>

          <Statistic size="tiny">
            <Statistic.Value>
              <span>{getEnglishText('totals : solar-consumed')}</span>
              {friendlyFormat('daily_total_solar_consumed', solarConsumed)[0]}
            </Statistic.Value>
            <Statistic.Label>{friendlyFormat('daily_total_solar_consumed', solarConsumed)[1]}</Statistic.Label>
          </Statistic>

          <Divider horizontal>
            <Header as="h4">
              {getEnglishText('totals : grid')}&nbsp;&nbsp;
              <Icon name="power cord" className="pv-red-color" />
            </Header>
          </Divider>
          <Statistic size="tiny">
            <Statistic.Value>
              <span>{getEnglishText('totals : grid-import')}</span>
              {friendlyFormat('daily_total_grid_import', gridImports)[0]}
            </Statistic.Value>
            <Statistic.Label>{friendlyFormat('daily_total_grid_import', gridImports)[1]}</Statistic.Label>
          </Statistic>
          <Statistic size="tiny">
            <Statistic.Value>
              <span>{getEnglishText('totals : grid-export')}</span>
              {friendlyFormat('daily_total_grid_export', gridExports)[0]}
            </Statistic.Value>
            <Statistic.Label>{friendlyFormat('daily_total_grid_export', gridExports)[1]}</Statistic.Label>
          </Statistic>

          <>
            <Divider horizontal>
              <Header as="h4">
                {getEnglishText('totals : home')}&nbsp;&nbsp;
                <Icon name="home" className="pv-green-color" />
              </Header>
            </Divider>
            <Statistic size="tiny">
              <Statistic.Value>
                <span>{getEnglishText('totals : home-consumed')}</span>
                {friendlyFormat('daily_total_home_consumed', homeConsumed)[0]}
              </Statistic.Value>
              <Statistic.Label>{friendlyFormat('daily_total_home_consumed', homeConsumed)[1]}</Statistic.Label>
            </Statistic>
          </>

          <Divider horizontal>
            <Header as="h4">
              {getEnglishText('totals : battery')}&nbsp;&nbsp;
              <Icon name="battery full" className="pv-blue-color" />
            </Header>
          </Divider>
          <Statistic size="tiny">
            <Statistic.Value>
              <span>{getEnglishText('totals : battery-discharge')}</span>
              {friendlyFormat('daily_total_battery_discharged', batteryDischarge)[0]}
            </Statistic.Value>
            <Statistic.Label>{friendlyFormat('daily_total_battery_discharged', batteryDischarge)[1]}</Statistic.Label>
          </Statistic>

          {displaySavings && <Savings isToday={isToday} units={units} startDateLocal={startDateLocal} endDateLocal={endDateLocal} rangeName={rangeName} />}
        </div>
      )}
    </Segment>
  )
}

export { Totals }
